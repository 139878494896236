:root {
  --default-button-border-radius: 6px;
}

.Button {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
  background: transparent;
  border: 1px solid color-mod(var(--color-border) blackness(5%));
  color: var(--color-text-dark);
  cursor: pointer;
  font-weight: bold;
  font-family: var(--mb-default-font-family), sans-serif;
  border-radius: var(--default-button-border-radius);
}

.Button:hover {
  color: var(--mb-color-brand);
  border-color: color-mod(var(--color-border) blackness(12%));
  background: var(--color-bg-light);
  transition: all 200ms linear;
  transition-property: color, border-color, background-color;
}

.Button:focus {
  outline: 2px solid var(--mb-color-focus);
}

.Button:focus:not(:focus-visible) {
  outline: none;
}

@media screen and (--breakpoint-min-lg) {
  .Button {
    padding: 0.75rem 1rem;
  }
}

.ButtonSmall {
  padding: 0.45rem 1rem;
}

.ButtonMedium {
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
}

.ButtonLarge {
  padding: 0.8rem 1.25rem;
  font-size: 1rem;
}

.ButtonNormal {
  font-weight: normal;
}

.ButtonRound {
  border-radius: 99px;
}

.ButtonPrimary {
  color: var(--color-text-white);
  background: var(--mb-color-brand);
  border: 1px solid var(--mb-color-brand);
}

.ButtonPrimary:hover {
  color: var(--color-text-white);
  border-color: var(--mb-color-brand);
  background-color: var(--mb-color-brand-alpha-88);
}

.ButtonWarning {
  color: var(--color-text-white);
  background: var(--color-error);
  border: 1px solid var(--color-error);
}

.ButtonWarning:hover {
  color: var(--color-text-white);
  border-color: var(--color-error);
  background-color: var(--color-error);
}

.ButtonCancel {
  border-radius: 99px;
}

.ButtonWhite {
  background-color: white;
  color: var(--color-text-dark);
  border-color: var(--color-border);
}

.ButtonBorderless {
  border-color: transparent;
  background: transparent;
  color: var(--color-text-medium);
}

.ButtonBorderless:hover {
  border-color: transparent;
  color: var(--color-text-medium);
}

.ButtonOnlyIcon {
  border: none;
  background: transparent;
  color: var(--color-text-dark);
  padding: 0;
}

.ButtonGroup {
  display: inline-block;
  border-radius: var(--default-button-border-radius);
  border: 1px solid var(--color-border);
  overflow: hidden;
  clear: both;
}

.ButtonGroup .Button {
  margin: 0;
  float: left;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-radius: 0;
  box-shadow: none;
}

.ButtonGroup .ButtonActive {
  background-color: var(--color-success);
  color: var(--color-text-white);
}

.ButtonGroup .Button:first-child {
  border-left: none;
}

.ButtonGroupBrand {
  border-color: white;
}

.ButtonGroupBrand .Button {
  border-color: white;
  color: var(--mb-color-brand);
  background-color: var(--color-bg-medium);
}

.ButtonGroupBrand .ButtonActive {
  background-color: var(--mb-color-brand);
  color: white;
}

.Button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ButtonDanger {
  background-color: var(--color-error);
  border-color: var(--color-error);
  color: var(--color-text-white);
}

.ButtonDanger:hover {
  color: white;
  background-color: var(--color-error);
  border-color: var(--color-error);
}

.ButtonSuccess {
  background-color: var(--color-success);
  border-color: var(--color-success);
  color: var(--color-text-white);
}

.ButtonSuccess:hover {
  background-color: var(--color-success);
  border-color: var(--color-success);
  color: var(--color-text-white);
}

.ButtonFullWidth {
  width: 100%;
}
