.TableInteractive {
  color: #4f575d;
  overflow: hidden;
}

.TableInteractiveHeaderCellData .cellData {
  font-weight: 900;
  font-size: 10px;
  border: 1px solid transparent;
  padding: 0.25em 0.65em;
  border-radius: 6px;
  min-width: 35px;
  color: var(--mb-color-brand);
}

.TableInteractiveHeaderCellData .cellData:hover {
  border: 1px solid transparent;
}

.TableInteractiveHeaderCellData :global(.Icon-chevrondown),
.TableInteractiveHeaderCellData :global(.Icon-chevronup) {
  opacity: 0.5;
}

.TableInteractiveHeader {
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-border);
}

.TableInteractive .TableInteractiveCellWrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid color-mod(var(--color-border) alpha(-70%));
}

.TableInteractive .TableInteractiveHeader,
.TableInteractive .TableInteractiveHeader .TableInteractiveCellWrapper,
.TableInteractive .TableInteractiveHeader .TableInteractiveCellWrapper:hover {
  background-color: var(--color-bg-white);
  background-image: none;
}

.TableInteractive .TableInteractiveHeader,
.TableInteractive .TableInteractiveHeader .TableInteractiveCellWrapper {
  background-color: var(--color-bg-white);
}

/* cell overflow ellipsis */
.TableInteractive .cellData {
  margin: 0 0.75em;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-weight: 700;
  font-size: 12.5px;
}

/* pivot */
.TableInteractive.TableInteractivePivot
  .TableInteractiveCellWrapperFirstColumn {
  border-right: 1px solid var(--color-border);
}

.TableInteractive
  .TableInteractiveHeader
  .TableInteractiveCellWrapper:global(.tether-enabled)
  .cellData {
  background-color: transparent;
  color: white !important;
}

.TableInteractiveCellWrapper:hover {
  background-color: transparent;
}

.TableID .cellData {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 0.25em 0.65em;
  border-radius: 99px;
  min-width: 35px;
  text-align: center;
  color: var(--mb-color-brand);
}

.TableInteractiveHeaderCellDataSorted {
  color: var(--mb-color-brand);
}

.TableInteractiveHeaderCellDataSorted :global(.Icon-chevrondown),
.TableInteractiveHeaderCellDataSorted :global(.Icon-chevronup) {
  opacity: 1;
  transition: opacity 0.3s linear;
}

.TableInteractiveCellWrapperFirstColumn.padLeft {
  padding-left: 1.5em;
}

.TableInteractiveGutter {
  background-color: var(--color-bg-white);
}
